<template>
  <v-row justify="center" id="signup-form-container">
    <v-col
      cols="10"
      sm="8"
      style="max-width: 348px; padding-top: 30px; padding-bottom: 0"
    >
      <form action="#" id="signup-form-53966c40" @submit.prevent="onSignup">
        <v-row justify="center">
          <v-col
            id="signup-google-button-65d49224"
            cols="12"
            class="pointer"
            style="
              padding: 10px 9px;
              background-color: #f4f4f4;
              border-radius: 5px;
            "
            @click="signupGoogle"
          >
            <div class="d-flex align-center justify-center">
              <div class="d-flex" style="margin-right: 20px">
                <Images name="google_icon.png" />
              </div>
              <div class="d-flex" style="padding-top: 2px">
                <Texting type="p2bold grey2">Sign up with Google</Texting>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="px-0"
            style="padding-top: 10px; padding-bottom: 10px"
          >
            <div class="d-flex align-center justify-center">
              <div class="d-flex px-0" style="width: 45%">
                <hr
                  class="ma-0"
                  style="
                    border: 1px solid #eadcec;
                    width: 100%;
                    border-bottom: none;
                  "
                />
              </div>
              <div
                class="d-flex px-0 justify-center"
                style="text-align: center; width: 10%"
              >
                <Texting type="p1 grey3">or</Texting>
              </div>
              <div class="d-flex px-0" style="width: 45%">
                <hr
                  class="ma-0"
                  style="
                    border: 1px solid #eadcec;
                    width: 100%;
                    border-bottom: none;
                  "
                />
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="px-0"
            style="padding-top: 0; padding-bottom: 0"
          >
            <!--                <Texting type="p1 grey2">Display Name</Texting>-->
            <v-text-field
              name="displayName"
              v-model="form.displayName"
              :class="{
                'error--text v-input--has-state':
                  ($v.form.displayName.$dirty &&
                    !$v.form.displayName.required) ||
                  ($v.form.displayName.$error &&
                    !$v.form.displayName.displayNameValid.valid &&
                    $v.form.displayName.required),
              }"
              id="displayName"
              label="Your Display Name"
              aria-required="true"
              style="border-color: #eadcec !important"
              hide-details
              outlined
            />
            <div class="mylabel">
              <Texting
                type="p1 Fivethly"
                v-if="
                  $v.form.displayName.$dirty && !$v.form.displayName.required
                "
                >Display Name is required</Texting
              >
              <Texting
                type="p1 Fivethly"
                v-else-if="
                  $v.form.displayName.$error &&
                  !$v.form.displayName.displayNameValid.valid &&
                  $v.form.displayName.required
                "
                >Display Name should contain only letters (a-z/A-Z)</Texting
              >
            </div>
          </v-col>

          <v-col cols="12" class="px-0" style="padding-bottom: 0">
            <!--                <Texting type="p1 grey2">Enter Your Email</Texting>-->
            <v-text-field
              name="email"
              v-model.trim="form.email"
              :class="{
                'error--text v-input--has-state':
                  ($v.form.email.$dirty && !$v.form.email.required) ||
                  ($v.form.email.$dirty && !$v.form.email.email),
              }"
              id="email"
              label="Your Email Address"
              aria-required="true"
              hide-details
              outlined
            />
            <div class="mylabel">
              <Texting
                type="p1 Fivethly"
                v-if="$v.form.email.$dirty && !$v.form.email.required"
                >Email is required</Texting
              >
              <Texting
                type="p1 Fivethly"
                v-if="$v.form.email.$dirty && !$v.form.email.email"
                >Email address is not valid</Texting
              >
            </div>
          </v-col>
          <v-col cols="12" class="px-0" style="padding-bottom: 0">
            <!--                <Texting type="p1 grey2">Create Password</Texting>-->
            <v-text-field
              name="password"
              v-model="form.password"
              :class="{
                'error--text v-input--has-state':
                  ($v.form.password.$dirty && !$v.form.password.required) ||
                  ($v.form.password.$dirty && !$v.form.password.minLength) ||
                  ($v.form.password.$error &&
                    !$v.form.password.strength.valid &&
                    $v.form.password.required),
              }"
              id="password"
              label="Password"
              :append-icon="show1 ? svgPathEye : svgPathEyeOff"
              @click:append="show1 = !show1"
              :type="show1 ? 'text' : 'password'"
              aria-required="true"
              class="signup-pass-input"
              hide-details
              outlined
            >
              <!--                            <template v-slot:append>
                                <v-icon @click="show1 = !show1" v-if="show1">{{ svgPathEye }}</v-icon>
                                <v-icon @click="show1 = !show1" v-else>{{ svgPathEyeOff }}</v-icon>
                            </template>-->
            </v-text-field>
            <div class="mylabel">
              <Texting
                type="p1 Fivethly"
                v-if="$v.form.password.$dirty && !$v.form.password.required"
                >Password is required</Texting
              >
              <Texting
                type="p1 Fivethly"
                v-if="$v.form.password.$dirty && !$v.form.password.minLength"
                >Password minimum length is 8 letters</Texting
              >
              <Texting
                type="p1 Fivethly"
                v-if="
                  $v.form.password.$error &&
                  !$v.form.password.strength.valid &&
                  $v.form.password.required
                "
              >
                At least 1 uppercase, 1 lowercase, 1 digit
                <!--<Texting>Must contain:</Texting>
                    <Texting>-Only latin characters</Texting>
                    <Texting>-At least 1 uppercase character</Texting>
                    <Texting>-At least 1 lowercase character</Texting>

<Texting>-At least 1 digit</Texting>-->
              </Texting>
            </div>
          </v-col>
          <v-col cols="12" class="px-0" style="padding-bottom: 0">
            <!--                <Texting type="p1 grey2">Confirm Password</Texting>-->
            <v-text-field
              name="confirmPassword"
              id="confirmPassword"
              v-model="form.confirmPassword"
              :class="{
                'error--text v-input--has-state':
                  $v.form.confirmPassword.$dirty &&
                  !$v.form.confirmPassword.sameAsPassword,
              }"
              label="Confirm Password"
              type="password"
              aria-required="true"
              hide-details
              outlined
            />
            <div class="mylabel">
              <Texting
                type="p1 Fivethly"
                v-if="
                  $v.form.confirmPassword.$dirty &&
                  !$v.form.confirmPassword.sameAsPassword
                "
                >Passwords do not match</Texting
              >
            </div>
          </v-col>
          <v-col cols="12" class="px-0 pb-0">
            <Button
              id="signup-create-account-button-8bf83f82"
              size="block"
              b_type="submit"
              :handleClick="clickSignUp"
              :disabled="submitting"
              type="primary"
              texting="p3bold mywhite"
              style="
                padding-bottom: 15px !important;
                padding-top: 15px !important;
              "
            >
              create
              <span style="text-transform: lowercase">an</span> account
            </Button>
          </v-col>
          <v-col
            cols="12"
            class="px-0"
            style="padding-top: 10px; padding-bottom: 0"
          >
            <v-row align="center" justify="center">
              <v-col
                cols="auto"
                style="
                  padding-bottom: 0;
                  padding-top: 0;
                  padding-right: 4px;
                  padding-left: 0;
                "
              >
                <Texting type="p1 grey2"
                  >By clicking Sign Up, you agree to our</Texting
                >
              </v-col>
              <v-col cols="auto" style="padding: 12px 4px 12px 0">
                <Texting
                  id="signup-terms-button-b03d2632"
                  class="pointer animate_back"
                  type="p1 grey10"
                  v-on:click.native="toTerms"
                  >Terms</Texting
                >
              </v-col>
              <v-col cols="auto" style="padding: 3px 4px 0 0">
                <Texting type="p1 grey2">and</Texting>
              </v-col>
              <v-col cols="auto" style="padding: 12px 0 12px 0">
                <Texting
                  id="signup-policy-button-bd898790"
                  v-on:click.native="toPolicy"
                  class="pointer animate_back"
                  type="p1 grey10"
                  >Policy</Texting
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </form>
      <Confirmation
        text1="Confirmation email has been"
        :text2="'sent to ' + user.email"
        ref="modal"
      />
    </v-col>
  </v-row>
</template>

<script>
import Texting from "./Texting";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import Button from "./Button.vue";
import Confirmation from "./Confirmation.vue";
import axios from "axios";
import { strength } from "../utils/passStrength.js";
import { displayNameValid } from "../utils/displayNameValid";
import Images from "./MyImages";
import myServices from "@/services/resource";
import { mdiEye } from "@mdi/js";
import { mdiEyeOff } from "@mdi/js";

export default {
  components: {
    Images,
    Texting,
    Confirmation,
    Button,
  },
  props: {
    opened: Boolean,
  },
  data: () => ({
    submitting: false,
    svgPathEye: mdiEye,
    svgPathEyeOff: mdiEyeOff,
    user: "",
    show1: false,
    form: {
      displayName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
  }),
  validations: {
    form: {
      displayName: {
        required,
        displayNameValid,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
        strength,
      },
      confirmPassword: {
        sameAsPassword: sameAs("password"),
      },
    },
  },
  watch: {
    opened: function (val) {
      if (val) {
        this.$v.$reset();
        this.form.displayName = "";
        this.form.email = "";
        this.form.password = "";
        this.form.confirmPassword = "";
      }
    },
  },
  methods: {
    clickSignUp() {
      myServices.myAnalyticsEvents(this, "Sign up", "Create an account");
      /*this.$ga.event('Sign up', 'Create an account');
            this.$mixpanel.track('Sign up', {
              action: 'Create an account',
            });*/
    },
    signupGoogle() {
      window.location.href = "/api/auth/google";
      myServices.myAnalyticsEvents(this, "Sign up", "Sign up with Google");
      /*this.$ga.event('Sign up', 'Sign up with Google');
            this.$mixpanel.track('Sign up', {
              action: 'Sign up with Google',
            });*/
    },
    toTerms() {
      let routeData = this.$router.resolve({
        name: "TermsPage",
      });
      window.open(routeData.href + "?blank=true", "_blank");
    },
    toPolicy() {
      let routeData = this.$router.resolve({
        name: "Policy",
      });
      window.open(routeData.href + "?blank=true", "_blank");
    },
    onSignup() {
      if (this.$v.$invalid) {
        this.$v.form.$touch();
        this.dialog = true;
        return;
      }

      this.submitting = true;

      axios
        .post("/api/auth/register", this.form)
        .then((res) => {
          this.submitting = false;
          this.user = res.data.data;
          this.$mixpanel.alias(this.user.userId);
          this.$analytics.fbq.event("Lead", {});
          this.close();
          this.$refs.modal.show();
        })
        .catch((error) => {
          this.submitting = false;
          this.$v.$reset();
          myServices.showErrorMessage(this, error);
        });
    },
    close: function () {
      this.$emit("close");
      this.$v.$reset();
      this.form.displayName = "";
      this.form.email = "";
      this.form.password = "";
      this.form.confirmPassword = "";
    },
  },
};
</script>

<style>
.signup-pass-input .v-icon {
  color: #a7a0b8 !important;
}
</style>
