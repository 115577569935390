var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-dialog',{staticStyle:{"z-index":"1000","overflow":"hidden"},attrs:{"id":"login-and-signup-modal-79e6bb82","overlay-opacity":"0.95","max-width":"660px"},on:{"click:outside":_vm.onClose},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticStyle:{"overflow":"hidden"},attrs:{"id":"login-and-signup-card-90b17758"}},[_c('v-container',[_c('v-row',{staticStyle:{"border-bottom":"1px solid #e0e0e0"},attrs:{"justify":"center"}},[_c('v-divider',{staticStyle:{"border":"#ffffff"}}),_c('v-col',{staticClass:"py-0 head-panel",staticStyle:{"max-width":"344px","margin-left":"66px"},attrs:{"cols":"7"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"py-0 px-0 px-sm-3",attrs:{"cols":"12"}},[_c('v-row',{staticStyle:{"text-align":"center"},attrs:{"align":"center"}},[_c('v-col',{staticClass:"pointer",staticStyle:{"padding-top":"16px","padding-bottom":"16px"},style:({
                      borderBottom:
                        _vm.value === 'signup'
                          ? '4px solid #9E33E0'
                          : '4px solid #ffffff',
                    }),attrs:{"cols":"6","id":"login-and-signup-modal-signup-button-ba3ce4b8"},on:{"click":_vm.onSignupClick}},[_c('Texting',{attrs:{"type":_vm.value === 'signup' ? 'p3bold grey10' : 'p3bold grey3'}},[_vm._v("Sign Up")])],1),_c('v-col',{staticClass:"pointer",staticStyle:{"padding-top":"16px","padding-bottom":"16px"},style:({
                      borderBottom:
                        _vm.value === 'login'
                          ? '4px solid #9E33E0'
                          : '4px solid #ffffff',
                    }),attrs:{"cols":"6","id":"login-and-signup-modal-login-button-c4ad62ba"},on:{"click":_vm.onLoginClick}},[_c('Texting',{attrs:{"type":_vm.value === 'login' ? 'p3bold grey10' : 'p3bold grey3'}},[_vm._v("Sign In")])],1)],1)],1)],1)],1),_c('v-divider',{staticStyle:{"border":"#ffffff"}}),_c('v-col',{staticStyle:{"padding-right":"20px","padding-top":"8px","min-width":"70px"},attrs:{"cols":"auto"}},[_c('div',{staticClass:"d-flex justify-end"},[_c('div',{staticClass:"d-flex"},[_c('Images',{staticClass:"pointer",attrs:{"id":"login-and-signup-modal-close-icon-e35c8a9c","name":"close.svg"},nativeOn:{"click":function($event){return _vm.onClose.apply(null, arguments)}}})],1)])])],1),_c('CheckLoginOrSignUp',{attrs:{"opened":_vm.opened,"closed":_vm.closed,"value":_vm.value},on:{"close":_vm.onClose}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }