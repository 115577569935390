<template>
  <div>
    <v-dialog
      v-model="dialog"
      id="forgot-pass-google-modal-ae971e42"
      overlay-opacity="0.8"
      max-width="657px"
      style="overflow: hidden"
    >
      <v-card
        style="
          background: #ffffff;
          box-shadow: 0 18px 23px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
        "
        id="forgot-pass-google-card-3bdfbe8a"
      >
        <v-container style="padding-bottom: 18px">
          <v-row align="center">
            <v-divider style="border: hidden" />
            <v-col cols="auto" style="padding-top: 8px; padding-right: 20px">
              <Images
                id="forgot-pass-google-close-icon-4a24385e"
                name="close.svg"
                class="pointer"
                v-on:click.native="close"
              />
            </v-col>
          </v-row>
          <v-row align="center" justify="center" style="padding-bottom: 60px">
            <v-col cols="auto" style="padding-top: 0; padding-bottom: 50px">
              <Texting type="p3 grey1"
                >Provided email address registered with Google
                authorization.</Texting
              >
            </v-col>
            <div style="width: 100%"></div>
            <v-col
              cols="12"
              class="pointer"
              style="
                padding: 12px 9px;
                background-color: #f4f4f4;
                border-radius: 5px;
                max-width: 300px;
              "
            >
              <div class="d-flex align-center justify-center">
                <div class="d-flex" style="margin-right: 20px">
                  <Images name="google_icon.png" />
                </div>
                <div class="d-flex">
                  <a href="/api/auth/google" class="text_decor">
                    <Texting
                      id="forgot-pass-google-button-9e01f36c"
                      type="p2bold grey2"
                      >Sign up with Google</Texting
                    >
                  </a>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Images from "./MyImages";
import Texting from "@/components/Texting";
export default {
  components: { Texting, Images },
  props: {
    active: Boolean,
  },
  data: () => ({
    dialog: false,
  }),
  watch: {
    active: function (val) {
      if (val) {
        this.dialog = true;
      }
    },
    dialog: function (val) {
      if (!val) {
        this.$emit("closed");
      }
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
  },
};
</script>
