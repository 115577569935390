<template>
  <div style="height: 70px; z-index: 1000">
    <v-app-bar
      id="app-header"
      fixed
      dense
      flat
      height="70px"
      style="z-index: 1000"
    >
      <v-toolbar-title class="align-center">
        <Images
          id="header-mobile-menu-icon-8bb43fda"
          @click.native="menu = true"
          style="padding-bottom: 2px; margin-right: 24px"
          class="d-inline-block d-sm-none pointer"
          name="header_menu_icon.svg"
        />
        <a href="/" @click="scrollToTop($event)">
          <Images
            id="header-logo-cae47d36"
            name="logo.svg"
            class="pointer ml-sm-3"
            style="padding-top: 7px"
        /></a>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="align-center d-flex">
        <div
          class="d-flex"
          :style="{
            borderBottom: activeClassMenu1
              ? '3px solid #9E33E0'
              : '3px solid #ffffff',
          }"
        >
          <a href="/Pricing" class="text_decor"
            ><Button
              id="header-pricing-29e8ba9a"
              size="small"
              type="float"
              texting="p2strong MainDark"
              class="px-md-5 px-3 d-none d-sm-flex menu-item gilroy-medium"
              style="
                padding-top: 21px !important;
                padding-bottom: 21px !important;
              "
              :handleClick="toPricing"
              >pricing</Button
            ></a
          >
        </div>
        <div
          class="d-flex"
          :style="{
            borderBottom: activeClassMenu2
              ? '3px solid #9E33E0'
              : '3px solid #ffffff',
          }"
        >
          <a href="/how-it-works" class="text_decor">
            <Button
              id="header-hiw-3ca3ea06"
              size="small"
              type="float"
              texting="p2strong MainDark"
              class="px-md-5 px-3 d-none d-sm-flex menu-item"
              style="
                padding-top: 21px !important;
                padding-bottom: 21px !important;
                white-space: nowrap;
              "
              :handleClick="scrollToHIW"
              >how
              <span style="text-transform: lowercase" class="gilroy-medium"
                >it</span
              >
              works</Button
            ></a
          >
        </div>
        <div
          class="d-flex"
          :style="{
            borderBottom: activeClassMenu4
              ? '3px solid #9E33E0'
              : '3px solid #ffffff',
          }"
        >
          <a href="/Blog" class="text_decor"
            ><Button
              id="header-blog-44e8ba9a"
              size="small"
              type="float"
              texting="p2strong MainDark"
              class="px-md-5 px-3 d-none d-sm-flex menu-item"
              style="
                padding-top: 21px !important;
                padding-bottom: 21px !important;
              "
              :handleClick="toBlog"
              >Blog</Button
            ></a
          >
        </div>
        <div class="header-vertical-line d-none d-sm-flex ml-3 mr-3"></div>
        <div
          class="d-flex"
          :style="{
            borderBottom: activeClassMenu3
              ? '3px solid #9E33E0'
              : '3px solid #ffffff',
          }"
        >
          <Button
            id="header-login-4c5c3a8e"
            size="small"
            type="float"
            texting="p2strong purple-heart"
            class="px-md-5 px-3 d-none d-sm-flex menu-item-login"
            :handleClick="onLogin"
            >login</Button
          >
        </div>
        <Button
          id="header-signup-6207cf24"
          size="small"
          style="border-radius: 20px !important"
          class="ml-md-5 ml-3 mr-md-10 mr-3 get-started"
          type="secondary"
          texting="p1strong grey10"
          :handleClick="onSignUp"
        >
          <span class="d-none d-sm-flex">Get Started</span>
          <span class="d-flex d-sm-none">start</span>
        </Button>
      </v-toolbar-items>
    </v-app-bar>
    <LoginAndSignUp
      @closed="active = false"
      :active="openModal()"
      :value="value"
      @onsignup-click="value = 'signup'"
      @onlogin-click="value = 'login'"
    />
    <transition name="background-menu">
      <div
        v-if="menu"
        @click="menu = false"
        class="menu-background d-block d-sm-none"
      ></div>
    </transition>
    <transition name="account-menu">
      <div v-if="this.menu" id="mobile-menu" class="d-block d-sm-none">
        <v-row>
          <v-col cols="auto" style="padding-top: 16px; padding-left: 23px">
            <Images
              id="header-mobile-close-9f0b9d74"
              class="pointer"
              @click.native="menu = false"
              name="mobile_menu_close.png"
            />
          </v-col>
        </v-row>
        <v-row justify="center">
          <a href="/Pricing" class="text_decor" style="width: 100%">
            <v-col
              id="header-mobile-pricing-24444842"
              cols="12"
              @click="toPricingMenu"
              style="margin-top: 28px; margin-bottom: 8px"
              v-wave="{
                color: '#9E33E0',
                startingOpacity: 0.3,
                easing: 'ease-in',
              }"
            >
              <Texting type="p3 MainDark" style="text-align: center"
                >Pricing</Texting
              >
            </v-col>
          </a>
          <a href="/how-it-works" class="text_decor" style="width: 100%">
            <v-col
              id="header-mobile-hiw-2a13c6e4"
              @click="hitMenu"
              cols="12"
              style="margin-bottom: 8px"
              v-wave="{
                color: '#9E33E0',
                startingOpacity: 0.3,
                easing: 'ease-in',
              }"
            >
              <Texting type="p3 MainDark" style="text-align: center"
                >How it Works</Texting
              >
            </v-col></a
          >
          <a href="/Blog" class="text_decor" style="width: 100%">
            <v-col
              id="header-mobile-blog-4c8b1dec"
              @click="toBlogMenu"
              cols="12"
              style="margin-bottom: 8px"
              v-wave="{
                color: '#9E33E0',
                startingOpacity: 0.3,
                easing: 'ease-in',
              }"
            >
              <Texting type="p3 MainDark" style="text-align: center"
                >Blog</Texting
              >
            </v-col>
          </a>
          <v-col
            id="header-mobile-login-2e608caa"
            @click="loginMenu"
            cols="12"
            style="margin-bottom: 16px"
            v-wave="{
              color: '#9E33E0',
              startingOpacity: 0.3,
              easing: 'ease-in',
            }"
          >
            <Texting type="p3 MainDark" style="text-align: center"
              >Login</Texting
            >
          </v-col>
          <v-col cols="auto">
            <Button
              id="header-mobile-signup-f1e9545a"
              size="small"
              style="
                border-radius: 50px !important;
                padding: 11px 48px !important;
              "
              class="get-started"
              type="secondary"
              texting="p2 grey10"
              :handleClick="signupMenu"
            >
              <span style="position: relative; top: 1px">Get Started</span>
            </Button>
          </v-col>
        </v-row>
      </div>
    </transition>
  </div>
</template>

<script>
import myServices from "../services/resource";
import Images from "./MyImages";
import LoginAndSignUp from "./LoginAndSignUp";
import Button from "./Button";
import router from "../plugins/router";
import Texting from "@/components/Texting";
export default {
  components: {
    Texting,
    Button,
    LoginAndSignUp,
    Images,
  },
  data: () => ({
    my_width: false,
    value: "",
    active: false,
    activeClassMenu1: false,
    activeClassMenu2: false,
    activeClassMenu3: false,
    activeClassMenu4: false,
    scrolled: false,
    menu: false,
    firstScroll: false,
  }),
  mounted() {
    if (this.$route.name === "Pricing") {
      this.activeClassMenu1 = true;
    }
    if (this.$route.name === "HowItWorks") {
      this.activeClassMenu2 = true;
    }
    if (this.$route.name === "BlogPage") {
      this.activeClassMenu4 = true;
    }
  },
  methods: {
    toPricingMenu() {
      this.menu = false;
      this.toPricing();
    },
    hitMenu() {
      this.menu = false;
      myServices.myAnalyticsEvents(
        this,
        "Homepage  Navigation bar",
        "How it works click",
      );
    },
    loginMenu() {
      this.menu = false;
      this.onLogin();
    },
    signupMenu() {
      this.menu = false;
      this.onSignUp();
    },
    toBlogMenu() {
      this.menu = false;
      this.toBlog();
    },
    toBlog() {
      myServices.myAnalyticsEvents(
        this,
        "Homepage  Navigation bar",
        "Blog click",
      );
    },
    toPricing() {
      myServices.myAnalyticsEvents(
        this,
        "Homepage  Navigation bar",
        "Pricing click",
      );
    },
    openModal() {
      return this.active;
    },
    onLogin() {
      this.value = "login";
      this.active = true;
      myServices.myAnalyticsEvents(
        this,
        "Homepage  Navigation bar",
        "Login click",
      );
    },
    onSignUp() {
      this.value = "signup";
      this.active = true;
      myServices.myAnalyticsEvents(
        this,
        "Homepage  Navigation bar",
        '"Get started" click',
      );
    },
    scrollToTop: function (event) {
      event.preventDefault();
      myServices.myAnalyticsEvents(
        this,
        "Homepage  Navigation bar",
        "Perkfection logo click",
      );
      if (this.$route.query.blank === "true") return;

      if (this.$route.name !== "home") {
        router.push({
          name: "home",
        });
        setTimeout(() => {
          let elmnt = document.getElementById("homepage_container");
          elmnt.scrollIntoView({
            behavior: "auto",
          });
        }, 100);
      } else {
        let element = document.getElementById("homepage_container");
        this.$scrollTo(element, 500);
      }
    },

    scrollToHIW: function () {
      myServices.myAnalyticsEvents(
        this,
        "Homepage  Navigation bar",
        "How it works click",
      );
    },
  },
};
</script>

<style scoped>
.account-menu-enter-active {
  transition: all 0.1s ease-in;
}

.account-menu-leave-active {
  transition: all 0.01s ease-in;
}

.account-menu-enter,
.account-menu-leave-to {
  transform: translateX(50px);
}

.background-menu-enter-active {
  transition: all 2s ease-in;
}

.background-menu-leave-active {
  transition: all 0.01s ease-in;
}

#mobile-menu {
  background-color: #ffffff;
  z-index: 2000;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  min-width: 310px;
  height: 100%;
  max-width: 310px;
  float: left;
  overflow: hidden;
}

.menu-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(158, 51, 224, 0.5);
  backdrop-filter: blur(8px);
  z-index: 1500;
  overflow: hidden;
}

.header-vertical-line {
  height: 23px;
  border-left: 1px solid #b2c0c8;
}
.menu-item {
  opacity: 0.7;
}
.menu-item:hover {
  opacity: 1;
}
.menu-item-login {
  border-radius: 50px;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
.menu-item-login:hover {
  background-color: #f4f4f4;
}
</style>
