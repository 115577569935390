<template>
  <v-row>
    <v-dialog
      id="login-and-signup-modal-79e6bb82"
      @click:outside="onClose"
      style="z-index: 1000; overflow: hidden"
      v-model="dialog"
      overlay-opacity="0.95"
      max-width="660px"
    >
      <v-card id="login-and-signup-card-90b17758" style="overflow: hidden">
        <v-container>
          <v-row justify="center" style="border-bottom: 1px solid #e0e0e0">
            <v-divider style="border: #ffffff"></v-divider>
            <v-col
              cols="7"
              style="max-width: 344px; margin-left: 66px"
              class="py-0 head-panel"
            >
              <v-row justify="center">
                <v-col cols="12" class="py-0 px-0 px-sm-3">
                  <v-row align="center" style="text-align: center">
                    <v-col
                      cols="6"
                      id="login-and-signup-modal-signup-button-ba3ce4b8"
                      @click="onSignupClick"
                      class="pointer"
                      :style="{
                        borderBottom:
                          value === 'signup'
                            ? '4px solid #9E33E0'
                            : '4px solid #ffffff',
                      }"
                      style="padding-top: 16px; padding-bottom: 16px"
                    >
                      <Texting
                        :type="
                          value === 'signup' ? 'p3bold grey10' : 'p3bold grey3'
                        "
                        >Sign Up</Texting
                      >
                    </v-col>
                    <v-col
                      cols="6"
                      id="login-and-signup-modal-login-button-c4ad62ba"
                      @click="onLoginClick"
                      class="pointer"
                      :style="{
                        borderBottom:
                          value === 'login'
                            ? '4px solid #9E33E0'
                            : '4px solid #ffffff',
                      }"
                      style="padding-top: 16px; padding-bottom: 16px"
                    >
                      <Texting
                        :type="
                          value === 'login' ? 'p3bold grey10' : 'p3bold grey3'
                        "
                        >Sign In</Texting
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-divider style="border: #ffffff"></v-divider>
            <v-col
              cols="auto"
              style="padding-right: 20px; padding-top: 8px; min-width: 70px"
            >
              <div class="d-flex justify-end">
                <div class="d-flex">
                  <Images
                    id="login-and-signup-modal-close-icon-e35c8a9c"
                    name="close.svg"
                    class="pointer"
                    v-on:click.native="onClose"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
          <CheckLoginOrSignUp
            @close="onClose"
            :opened="opened"
            :closed="closed"
            :value="value"
          />
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Images from "./MyImages";
import Texting from "./Texting";
import CheckLoginOrSignUp from "./CheckLoginOrSignUp";
export default {
  components: {
    CheckLoginOrSignUp,
    Texting,
    Images,
  },
  props: {
    value: String,
    active: Boolean,
  },
  data: () => ({
    dialog: false,
    closed: false,
    opened: false,
  }),
  watch: {
    active: function (val) {
      if (val) {
        this.dialog = true;
      }
    },
    dialog: function (val) {
      if (!val) {
        this.$emit("closed");
        this.closed = true;
        this.opened = false;
      } else {
        this.opened = true;
      }
    },
  },
  methods: {
    onSignupClick() {
      this.$emit("onsignup-click");
    },
    onLoginClick() {
      this.$emit("onlogin-click");
    },
    onClose() {
      this.dialog = false;
    },
  },
};
</script>

<style>
@media screen and (max-width: 400px) {
  .head-panel {
    margin-left: 10px !important;
  }
}
</style>
