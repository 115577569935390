<template>
  <div>
    <component
      :opened="opened"
      @close="onClose"
      :is="dynamicComponent"
    ></component>
  </div>
</template>

<script>
import Login from "./Login";
import SignUp from "./SignUp";
export default {
  components: {
    Login,
    SignUp,
  },
  props: {
    value: String,
    closed: Boolean,
    opened: Boolean,
  },
  computed: {
    dynamicComponent() {
      if (this.value === "login") {
        return Login;
      }
      if (this.value === "signup") {
        return SignUp;
      }
      return "select component";
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
  },
};
</script>
