<template>
  <v-row justify="center">
    <v-col cols="10" sm="8" style="max-width: 348px; padding-top: 53px">
      <form action="#" ref="form" id="loginForm" @submit.prevent="onLogin">
        <v-row justify="center">
          <v-col
            id="signin-google-button-c8bc3d84"
            cols="12"
            class="pointer"
            style="
              padding: 12px 9px;
              background-color: #f4f4f4;
              border-radius: 5px;
            "
            @click="signinGoogle"
          >
            <div class="d-flex align-center justify-center">
              <div class="d-flex" style="margin-right: 20px">
                <Images name="google_icon.png" />
              </div>
              <div class="d-flex" style="padding-top: 2px">
                <!--		<a href="/api/auth/google" class="text_decor">

<Texting type="p2bold grey2">Sign in with Google</Texting>

</a>-->
                <Texting type="p2bold grey2">Sign in with Google</Texting>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="px-0"
            style="padding-top: 19px; padding-bottom: 19px"
          >
            <div class="d-flex align-center justify-center">
              <div class="d-flex px-0" style="width: 45%">
                <hr
                  class="ma-0"
                  style="
                    border: 1px solid #eadcec;
                    width: 100%;
                    border-bottom: none;
                  "
                />
              </div>
              <div
                class="d-flex px-0 justify-center"
                style="text-align: center; width: 10%"
              >
                <Texting type="p1 grey3" ref="or">or</Texting>
              </div>
              <div class="d-flex px-0" style="width: 45%">
                <hr
                  class="ma-0"
                  style="
                    border: 1px solid #eadcec;
                    width: 100%;
                    border-bottom: none;
                  "
                />
              </div>
            </div>
          </v-col>
          <v-col cols="12" style="padding: 0 0 15px 0">
            <v-text-field
              id="login-email-input-e7dacbd6"
              name="email"
              v-model.trim="form.email"
              label="Your Email Address"
              :class="{
                'error--text v-input--has-state':
                  ($v.form.email.$dirty && !$v.form.email.required) ||
                  ($v.form.email.$dirty && !$v.form.email.email),
              }"
              aria-required="true"
              type="email"
              hide-details
              outlined
            />
            <div class="mylabel">
              <Texting
                type="p1 Fivethly"
                v-if="$v.form.email.$dirty && !$v.form.email.required"
                >Email is required</Texting
              >
              <Texting
                type="p1 Fivethly"
                v-else-if="$v.form.email.$dirty && !$v.form.email.email"
                >Email address is not valid</Texting
              >
            </div>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-text-field
              :append-icon="show1 ? svgPathEye : svgPathEyeOff"
              @click:append="show1 = !show1"
              id="login-password-input-fa00ccc0"
              class="login-pass-input"
              name="password"
              @click="handlePassClick"
              :autofocus="true"
              v-model="form.password"
              label="Password"
              :class="{
                'error--text v-input--has-state':
                  $v.form.password.$dirty && !$v.form.password.required,
              }"
              :type="show1 ? 'text' : 'password'"
              aria-required="true"
              ref="pass"
              hide-details
              outlined
            />
            <div
              v-if="$v.form.password.$dirty && !$v.form.password.required"
              class="mylabel"
            >
              <Texting type="p1 Fivethly">Password is required</Texting>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="px-0"
            style="padding-top: 15px; padding-bottom: 0"
          >
            <Button
              id="login-button-08cc1b1a"
              b_type="submit"
              size="block"
              texting="p3bold mywhite"
              :handleClick="clickLogin"
              type="primary"
              style="
                padding-top: 15px !important;
                padding-bottom: 15px !important;
              "
              >login</Button
            >
          </v-col>
          <v-col
            id="login-forgot-text-2805b73e"
            cols="auto"
            class="px-0"
            @click="openConfirm1"
            style="padding-top: 50px; padding-bottom: 17px"
          >
            <Texting type="p1 thirthly pointer">Forgot your password?</Texting>
          </v-col>
        </v-row>
        <ForgotPassword ref="modalf" />
      </form>
    </v-col>
  </v-row>
</template>

<script>
import myServices from "../services/resource";
import Texting from "./Texting";
import ForgotPassword from "./ForgotPassword";
import { required, email } from "vuelidate/lib/validators";
import Button from "./Button.vue";
import store from "../store";
import Images from "./MyImages";
import { mdiEye, mdiEyeOff } from "@mdi/js";

export default {
  components: {
    Images,
    Texting,
    ForgotPassword,
    Button,
  },
  props: {
    closed: Boolean,
    opened: Boolean,
  },
  data: () => ({
    checkbox: false,
    svgPathEye: mdiEye,
    svgPathEyeOff: mdiEyeOff,
    show1: false,
    form: {
      email: "",
      password: "",
    },
  }),
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  watch: {
    closed: function (val) {
      if (val) {
        this.close();
      }
    },
    opened: function (val) {
      if (val) {
        this.$v.$reset();
        this.form.email = "";
        this.form.password = "";
      }
    },
  },
  mounted() {
    this.form.email = " ";
  },
  methods: {
    clickLogin() {
      myServices.myAnalyticsEvents(this, "Sign in", "Log In");
    },
    handlePassClick() {
      if (this.form.email === " ") this.form.email = "";
    },
    signinGoogle() {
      window.location.href = "/api/auth/google";
      myServices.myAnalyticsEvents(this, "Sign in", "Sign in with Google");
      /*this.$ga.event('Sign in', 'Sign in with Google');
            this.$mixpanel.track('Sign in', {
              action: 'Sign in with Google',
            });*/
    },
    onCheckboxChange: function (val) {
      this.checkbox = val;
    },
    onLogin() {
      if (this.$v.$invalid) {
        this.$v.form.$touch();
        return;
      }
      let data = {
        form: this.form,
        query: this.$route.query,
      };
      store.dispatch("login", data).then((a) => {
        if (a.error) {
          // eslint-disable-next-line no-console
          console.log(a.error);
          myServices.showErrorMessage(this, a.error);
          return;
        }
        this.close();
      });
    },
    close: function () {
      this.$emit("close");
      this.$v.$reset();
      this.form.email = "";
      this.form.password = "";
    },
    openConfirm1: function () {
      this.close();
      return this.$refs.modalf.show2();
    },
  },
};
</script>

<style>
.login-pass-input .v-icon {
  color: #a7a0b8 !important;
}
</style>
