var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"70px","z-index":"1000"}},[_c('v-app-bar',{staticStyle:{"z-index":"1000"},attrs:{"id":"app-header","fixed":"","dense":"","flat":"","height":"70px"}},[_c('v-toolbar-title',{staticClass:"align-center"},[_c('Images',{staticClass:"d-inline-block d-sm-none pointer",staticStyle:{"padding-bottom":"2px","margin-right":"24px"},attrs:{"id":"header-mobile-menu-icon-8bb43fda","name":"header_menu_icon.svg"},nativeOn:{"click":function($event){_vm.menu = true}}}),_c('a',{attrs:{"href":"/"},on:{"click":function($event){return _vm.scrollToTop($event)}}},[_c('Images',{staticClass:"pointer ml-sm-3",staticStyle:{"padding-top":"7px"},attrs:{"id":"header-logo-cae47d36","name":"logo.svg"}})],1)],1),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"align-center d-flex"},[_c('div',{staticClass:"d-flex",style:({
          borderBottom: _vm.activeClassMenu1
            ? '3px solid #9E33E0'
            : '3px solid #ffffff',
        })},[_c('a',{staticClass:"text_decor",attrs:{"href":"/Pricing"}},[_c('Button',{staticClass:"px-md-5 px-3 d-none d-sm-flex menu-item gilroy-medium",staticStyle:{"padding-top":"21px !important","padding-bottom":"21px !important"},attrs:{"id":"header-pricing-29e8ba9a","size":"small","type":"float","texting":"p2strong MainDark","handleClick":_vm.toPricing}},[_vm._v("pricing")])],1)]),_c('div',{staticClass:"d-flex",style:({
          borderBottom: _vm.activeClassMenu2
            ? '3px solid #9E33E0'
            : '3px solid #ffffff',
        })},[_c('a',{staticClass:"text_decor",attrs:{"href":"/how-it-works"}},[_c('Button',{staticClass:"px-md-5 px-3 d-none d-sm-flex menu-item",staticStyle:{"padding-top":"21px !important","padding-bottom":"21px !important","white-space":"nowrap"},attrs:{"id":"header-hiw-3ca3ea06","size":"small","type":"float","texting":"p2strong MainDark","handleClick":_vm.scrollToHIW}},[_vm._v("how "),_c('span',{staticClass:"gilroy-medium",staticStyle:{"text-transform":"lowercase"}},[_vm._v("it")]),_vm._v(" works")])],1)]),_c('div',{staticClass:"d-flex",style:({
          borderBottom: _vm.activeClassMenu4
            ? '3px solid #9E33E0'
            : '3px solid #ffffff',
        })},[_c('a',{staticClass:"text_decor",attrs:{"href":"/Blog"}},[_c('Button',{staticClass:"px-md-5 px-3 d-none d-sm-flex menu-item",staticStyle:{"padding-top":"21px !important","padding-bottom":"21px !important"},attrs:{"id":"header-blog-44e8ba9a","size":"small","type":"float","texting":"p2strong MainDark","handleClick":_vm.toBlog}},[_vm._v("Blog")])],1)]),_c('div',{staticClass:"header-vertical-line d-none d-sm-flex ml-3 mr-3"}),_c('div',{staticClass:"d-flex",style:({
          borderBottom: _vm.activeClassMenu3
            ? '3px solid #9E33E0'
            : '3px solid #ffffff',
        })},[_c('Button',{staticClass:"px-md-5 px-3 d-none d-sm-flex menu-item-login",attrs:{"id":"header-login-4c5c3a8e","size":"small","type":"float","texting":"p2strong purple-heart","handleClick":_vm.onLogin}},[_vm._v("login")])],1),_c('Button',{staticClass:"ml-md-5 ml-3 mr-md-10 mr-3 get-started",staticStyle:{"border-radius":"20px !important"},attrs:{"id":"header-signup-6207cf24","size":"small","type":"secondary","texting":"p1strong grey10","handleClick":_vm.onSignUp}},[_c('span',{staticClass:"d-none d-sm-flex"},[_vm._v("Get Started")]),_c('span',{staticClass:"d-flex d-sm-none"},[_vm._v("start")])])],1)],1),_c('LoginAndSignUp',{attrs:{"active":_vm.openModal(),"value":_vm.value},on:{"closed":function($event){_vm.active = false},"onsignup-click":function($event){_vm.value = 'signup'},"onlogin-click":function($event){_vm.value = 'login'}}}),_c('transition',{attrs:{"name":"background-menu"}},[(_vm.menu)?_c('div',{staticClass:"menu-background d-block d-sm-none",on:{"click":function($event){_vm.menu = false}}}):_vm._e()]),_c('transition',{attrs:{"name":"account-menu"}},[(this.menu)?_c('div',{staticClass:"d-block d-sm-none",attrs:{"id":"mobile-menu"}},[_c('v-row',[_c('v-col',{staticStyle:{"padding-top":"16px","padding-left":"23px"},attrs:{"cols":"auto"}},[_c('Images',{staticClass:"pointer",attrs:{"id":"header-mobile-close-9f0b9d74","name":"mobile_menu_close.png"},nativeOn:{"click":function($event){_vm.menu = false}}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('a',{staticClass:"text_decor",staticStyle:{"width":"100%"},attrs:{"href":"/Pricing"}},[_c('v-col',{directives:[{name:"wave",rawName:"v-wave",value:({
              color: '#9E33E0',
              startingOpacity: 0.3,
              easing: 'ease-in',
            }),expression:"{\n              color: '#9E33E0',\n              startingOpacity: 0.3,\n              easing: 'ease-in',\n            }"}],staticStyle:{"margin-top":"28px","margin-bottom":"8px"},attrs:{"id":"header-mobile-pricing-24444842","cols":"12"},on:{"click":_vm.toPricingMenu}},[_c('Texting',{staticStyle:{"text-align":"center"},attrs:{"type":"p3 MainDark"}},[_vm._v("Pricing")])],1)],1),_c('a',{staticClass:"text_decor",staticStyle:{"width":"100%"},attrs:{"href":"/how-it-works"}},[_c('v-col',{directives:[{name:"wave",rawName:"v-wave",value:({
              color: '#9E33E0',
              startingOpacity: 0.3,
              easing: 'ease-in',
            }),expression:"{\n              color: '#9E33E0',\n              startingOpacity: 0.3,\n              easing: 'ease-in',\n            }"}],staticStyle:{"margin-bottom":"8px"},attrs:{"id":"header-mobile-hiw-2a13c6e4","cols":"12"},on:{"click":_vm.hitMenu}},[_c('Texting',{staticStyle:{"text-align":"center"},attrs:{"type":"p3 MainDark"}},[_vm._v("How it Works")])],1)],1),_c('a',{staticClass:"text_decor",staticStyle:{"width":"100%"},attrs:{"href":"/Blog"}},[_c('v-col',{directives:[{name:"wave",rawName:"v-wave",value:({
              color: '#9E33E0',
              startingOpacity: 0.3,
              easing: 'ease-in',
            }),expression:"{\n              color: '#9E33E0',\n              startingOpacity: 0.3,\n              easing: 'ease-in',\n            }"}],staticStyle:{"margin-bottom":"8px"},attrs:{"id":"header-mobile-blog-4c8b1dec","cols":"12"},on:{"click":_vm.toBlogMenu}},[_c('Texting',{staticStyle:{"text-align":"center"},attrs:{"type":"p3 MainDark"}},[_vm._v("Blog")])],1)],1),_c('v-col',{directives:[{name:"wave",rawName:"v-wave",value:({
            color: '#9E33E0',
            startingOpacity: 0.3,
            easing: 'ease-in',
          }),expression:"{\n            color: '#9E33E0',\n            startingOpacity: 0.3,\n            easing: 'ease-in',\n          }"}],staticStyle:{"margin-bottom":"16px"},attrs:{"id":"header-mobile-login-2e608caa","cols":"12"},on:{"click":_vm.loginMenu}},[_c('Texting',{staticStyle:{"text-align":"center"},attrs:{"type":"p3 MainDark"}},[_vm._v("Login")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('Button',{staticClass:"get-started",staticStyle:{"border-radius":"50px !important","padding":"11px 48px !important"},attrs:{"id":"header-mobile-signup-f1e9545a","size":"small","type":"secondary","texting":"p2 grey10","handleClick":_vm.signupMenu}},[_c('span',{staticStyle:{"position":"relative","top":"1px"}},[_vm._v("Get Started")])])],1)],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }