var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticStyle:{"max-width":"348px","padding-top":"53px"},attrs:{"cols":"10","sm":"8"}},[_c('form',{ref:"form",attrs:{"action":"#","id":"loginForm"},on:{"submit":function($event){$event.preventDefault();return _vm.onLogin.apply(null, arguments)}}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pointer",staticStyle:{"padding":"12px 9px","background-color":"#f4f4f4","border-radius":"5px"},attrs:{"id":"signin-google-button-c8bc3d84","cols":"12"},on:{"click":_vm.signinGoogle}},[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('div',{staticClass:"d-flex",staticStyle:{"margin-right":"20px"}},[_c('Images',{attrs:{"name":"google_icon.png"}})],1),_c('div',{staticClass:"d-flex",staticStyle:{"padding-top":"2px"}},[_c('Texting',{attrs:{"type":"p2bold grey2"}},[_vm._v("Sign in with Google")])],1)])]),_c('v-col',{staticClass:"px-0",staticStyle:{"padding-top":"19px","padding-bottom":"19px"},attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('div',{staticClass:"d-flex px-0",staticStyle:{"width":"45%"}},[_c('hr',{staticClass:"ma-0",staticStyle:{"border":"1px solid #eadcec","width":"100%","border-bottom":"none"}})]),_c('div',{staticClass:"d-flex px-0 justify-center",staticStyle:{"text-align":"center","width":"10%"}},[_c('Texting',{ref:"or",attrs:{"type":"p1 grey3"}},[_vm._v("or")])],1),_c('div',{staticClass:"d-flex px-0",staticStyle:{"width":"45%"}},[_c('hr',{staticClass:"ma-0",staticStyle:{"border":"1px solid #eadcec","width":"100%","border-bottom":"none"}})])])]),_c('v-col',{staticStyle:{"padding":"0 0 15px 0"},attrs:{"cols":"12"}},[_c('v-text-field',{class:{
                'error--text v-input--has-state':
                  (_vm.$v.form.email.$dirty && !_vm.$v.form.email.required) ||
                  (_vm.$v.form.email.$dirty && !_vm.$v.form.email.email),
              },attrs:{"id":"login-email-input-e7dacbd6","name":"email","label":"Your Email Address","aria-required":"true","type":"email","hide-details":"","outlined":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.email"}}),_c('div',{staticClass:"mylabel"},[(_vm.$v.form.email.$dirty && !_vm.$v.form.email.required)?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Email is required")]):(_vm.$v.form.email.$dirty && !_vm.$v.form.email.email)?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Email address is not valid")]):_vm._e()],1)],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-text-field',{ref:"pass",staticClass:"login-pass-input",class:{
                'error--text v-input--has-state':
                  _vm.$v.form.password.$dirty && !_vm.$v.form.password.required,
              },attrs:{"append-icon":_vm.show1 ? _vm.svgPathEye : _vm.svgPathEyeOff,"id":"login-password-input-fa00ccc0","name":"password","autofocus":true,"label":"Password","type":_vm.show1 ? 'text' : 'password',"aria-required":"true","hide-details":"","outlined":""},on:{"click:append":function($event){_vm.show1 = !_vm.show1},"click":_vm.handlePassClick},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),(_vm.$v.form.password.$dirty && !_vm.$v.form.password.required)?_c('div',{staticClass:"mylabel"},[_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Password is required")])],1):_vm._e()],1),_c('v-col',{staticClass:"px-0",staticStyle:{"padding-top":"15px","padding-bottom":"0"},attrs:{"cols":"12"}},[_c('Button',{staticStyle:{"padding-top":"15px !important","padding-bottom":"15px !important"},attrs:{"id":"login-button-08cc1b1a","b_type":"submit","size":"block","texting":"p3bold mywhite","handleClick":_vm.clickLogin,"type":"primary"}},[_vm._v("login")])],1),_c('v-col',{staticClass:"px-0",staticStyle:{"padding-top":"50px","padding-bottom":"17px"},attrs:{"id":"login-forgot-text-2805b73e","cols":"auto"},on:{"click":_vm.openConfirm1}},[_c('Texting',{attrs:{"type":"p1 thirthly pointer"}},[_vm._v("Forgot your password?")])],1)],1),_c('ForgotPassword',{ref:"modalf"})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }