var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center","id":"signup-form-container"}},[_c('v-col',{staticStyle:{"max-width":"348px","padding-top":"30px","padding-bottom":"0"},attrs:{"cols":"10","sm":"8"}},[_c('form',{attrs:{"action":"#","id":"signup-form-53966c40"},on:{"submit":function($event){$event.preventDefault();return _vm.onSignup.apply(null, arguments)}}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pointer",staticStyle:{"padding":"10px 9px","background-color":"#f4f4f4","border-radius":"5px"},attrs:{"id":"signup-google-button-65d49224","cols":"12"},on:{"click":_vm.signupGoogle}},[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('div',{staticClass:"d-flex",staticStyle:{"margin-right":"20px"}},[_c('Images',{attrs:{"name":"google_icon.png"}})],1),_c('div',{staticClass:"d-flex",staticStyle:{"padding-top":"2px"}},[_c('Texting',{attrs:{"type":"p2bold grey2"}},[_vm._v("Sign up with Google")])],1)])]),_c('v-col',{staticClass:"px-0",staticStyle:{"padding-top":"10px","padding-bottom":"10px"},attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('div',{staticClass:"d-flex px-0",staticStyle:{"width":"45%"}},[_c('hr',{staticClass:"ma-0",staticStyle:{"border":"1px solid #eadcec","width":"100%","border-bottom":"none"}})]),_c('div',{staticClass:"d-flex px-0 justify-center",staticStyle:{"text-align":"center","width":"10%"}},[_c('Texting',{attrs:{"type":"p1 grey3"}},[_vm._v("or")])],1),_c('div',{staticClass:"d-flex px-0",staticStyle:{"width":"45%"}},[_c('hr',{staticClass:"ma-0",staticStyle:{"border":"1px solid #eadcec","width":"100%","border-bottom":"none"}})])])]),_c('v-col',{staticClass:"px-0",staticStyle:{"padding-top":"0","padding-bottom":"0"},attrs:{"cols":"12"}},[_c('v-text-field',{class:{
                'error--text v-input--has-state':
                  (_vm.$v.form.displayName.$dirty &&
                    !_vm.$v.form.displayName.required) ||
                  (_vm.$v.form.displayName.$error &&
                    !_vm.$v.form.displayName.displayNameValid.valid &&
                    _vm.$v.form.displayName.required),
              },staticStyle:{"border-color":"#eadcec !important"},attrs:{"name":"displayName","id":"displayName","label":"Your Display Name","aria-required":"true","hide-details":"","outlined":""},model:{value:(_vm.form.displayName),callback:function ($$v) {_vm.$set(_vm.form, "displayName", $$v)},expression:"form.displayName"}}),_c('div',{staticClass:"mylabel"},[(
                  _vm.$v.form.displayName.$dirty && !_vm.$v.form.displayName.required
                )?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Display Name is required")]):(
                  _vm.$v.form.displayName.$error &&
                  !_vm.$v.form.displayName.displayNameValid.valid &&
                  _vm.$v.form.displayName.required
                )?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Display Name should contain only letters (a-z/A-Z)")]):_vm._e()],1)],1),_c('v-col',{staticClass:"px-0",staticStyle:{"padding-bottom":"0"},attrs:{"cols":"12"}},[_c('v-text-field',{class:{
                'error--text v-input--has-state':
                  (_vm.$v.form.email.$dirty && !_vm.$v.form.email.required) ||
                  (_vm.$v.form.email.$dirty && !_vm.$v.form.email.email),
              },attrs:{"name":"email","id":"email","label":"Your Email Address","aria-required":"true","hide-details":"","outlined":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.email"}}),_c('div',{staticClass:"mylabel"},[(_vm.$v.form.email.$dirty && !_vm.$v.form.email.required)?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Email is required")]):_vm._e(),(_vm.$v.form.email.$dirty && !_vm.$v.form.email.email)?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Email address is not valid")]):_vm._e()],1)],1),_c('v-col',{staticClass:"px-0",staticStyle:{"padding-bottom":"0"},attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"signup-pass-input",class:{
                'error--text v-input--has-state':
                  (_vm.$v.form.password.$dirty && !_vm.$v.form.password.required) ||
                  (_vm.$v.form.password.$dirty && !_vm.$v.form.password.minLength) ||
                  (_vm.$v.form.password.$error &&
                    !_vm.$v.form.password.strength.valid &&
                    _vm.$v.form.password.required),
              },attrs:{"name":"password","id":"password","label":"Password","append-icon":_vm.show1 ? _vm.svgPathEye : _vm.svgPathEyeOff,"type":_vm.show1 ? 'text' : 'password',"aria-required":"true","hide-details":"","outlined":""},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('div',{staticClass:"mylabel"},[(_vm.$v.form.password.$dirty && !_vm.$v.form.password.required)?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Password is required")]):_vm._e(),(_vm.$v.form.password.$dirty && !_vm.$v.form.password.minLength)?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Password minimum length is 8 letters")]):_vm._e(),(
                  _vm.$v.form.password.$error &&
                  !_vm.$v.form.password.strength.valid &&
                  _vm.$v.form.password.required
                )?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v(" At least 1 uppercase, 1 lowercase, 1 digit ")]):_vm._e()],1)],1),_c('v-col',{staticClass:"px-0",staticStyle:{"padding-bottom":"0"},attrs:{"cols":"12"}},[_c('v-text-field',{class:{
                'error--text v-input--has-state':
                  _vm.$v.form.confirmPassword.$dirty &&
                  !_vm.$v.form.confirmPassword.sameAsPassword,
              },attrs:{"name":"confirmPassword","id":"confirmPassword","label":"Confirm Password","type":"password","aria-required":"true","hide-details":"","outlined":""},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}}),_c('div',{staticClass:"mylabel"},[(
                  _vm.$v.form.confirmPassword.$dirty &&
                  !_vm.$v.form.confirmPassword.sameAsPassword
                )?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Passwords do not match")]):_vm._e()],1)],1),_c('v-col',{staticClass:"px-0 pb-0",attrs:{"cols":"12"}},[_c('Button',{staticStyle:{"padding-bottom":"15px !important","padding-top":"15px !important"},attrs:{"id":"signup-create-account-button-8bf83f82","size":"block","b_type":"submit","handleClick":_vm.clickSignUp,"disabled":_vm.submitting,"type":"primary","texting":"p3bold mywhite"}},[_vm._v(" create "),_c('span',{staticStyle:{"text-transform":"lowercase"}},[_vm._v("an")]),_vm._v(" account ")])],1),_c('v-col',{staticClass:"px-0",staticStyle:{"padding-top":"10px","padding-bottom":"0"},attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticStyle:{"padding-bottom":"0","padding-top":"0","padding-right":"4px","padding-left":"0"},attrs:{"cols":"auto"}},[_c('Texting',{attrs:{"type":"p1 grey2"}},[_vm._v("By clicking Sign Up, you agree to our")])],1),_c('v-col',{staticStyle:{"padding":"12px 4px 12px 0"},attrs:{"cols":"auto"}},[_c('Texting',{staticClass:"pointer animate_back",attrs:{"id":"signup-terms-button-b03d2632","type":"p1 grey10"},nativeOn:{"click":function($event){return _vm.toTerms.apply(null, arguments)}}},[_vm._v("Terms")])],1),_c('v-col',{staticStyle:{"padding":"3px 4px 0 0"},attrs:{"cols":"auto"}},[_c('Texting',{attrs:{"type":"p1 grey2"}},[_vm._v("and")])],1),_c('v-col',{staticStyle:{"padding":"12px 0 12px 0"},attrs:{"cols":"auto"}},[_c('Texting',{staticClass:"pointer animate_back",attrs:{"id":"signup-policy-button-bd898790","type":"p1 grey10"},nativeOn:{"click":function($event){return _vm.toPolicy.apply(null, arguments)}}},[_vm._v("Policy")])],1)],1)],1)],1)],1),_c('Confirmation',{ref:"modal",attrs:{"text1":"Confirmation email has been","text2":'sent to ' + _vm.user.email}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }