<template>
  <v-row>
    <v-dialog
      id="forgot-password-modal-d81f3d18"
      v-model="dialog"
      overlay-opacity="0.95"
      max-width="500px"
      min-wi
    >
      <v-card id="forgot-password-card-e695176e">
        <v-container style="padding-bottom: 36px">
          <v-row
            justify="space-between"
            style="border-bottom: 1px solid #f4f4f4"
          >
            <v-col cols="auto>" style="padding-left: 45px; padding-top: 14px">
              <Texting type="p2bold grey2">Password Reset</Texting>
            </v-col>
            <v-col cols="auto" style="padding-right: 20px; padding-top: 8px">
              <Images
                id="forgot-password-close-icon-f9ba0bec"
                name="close.svg"
                class="pointer"
                v-on:click.native="hide1"
              />
            </v-col>
          </v-row>
          <form action="#">
            <v-row justify="center">
              <v-col cols="9">
                <v-row justify="center">
                  <v-col
                    cols="12"
                    style="padding-top: 48px; padding-bottom: 3px"
                  >
                    <v-text-field
                      @blur="$v.form.email.$touch()"
                      name="email"
                      id="forgot-password-email-input-139b7ef6"
                      v-model="form.email"
                      label="Your Email Address"
                      aria-required="true"
                      v-bind:class="{
                        'error--text v-input--has-state': $v.form.email.$error,
                      }"
                      hide-details
                      outlined
                    />
                    <div v-if="$v.form.email.$error" class="mylabel">
                      <Texting type="p1 Fivethly" v-if="!$v.form.email.required"
                        >Email is required</Texting
                      >
                      <Texting type="p1 Fivethly" v-if="!$v.form.email.email"
                        >Email address is not valid</Texting
                      >
                    </div>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="12">
                    <Button
                      id="forgot-password-send-button-2592adb4"
                      :disabled="$v.form.email.$error"
                      type="primary"
                      size="block"
                      texting="p3bold mywhite"
                      :handleClick="onForgot"
                      style="
                        padding-top: 15px !important;
                        padding-bottom: 15px !important;
                      "
                      >send</Button
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </form>
        </v-container>
      </v-card>
    </v-dialog>
    <Confirmation
      text1="Password Reset Email Sent"
      text2="Follow the directions in the email to reset your password"
      ref="confirmation"
    />
    <ForgotPassGoogle @closed="forgot_google = false" :active="forgot_google" />
  </v-row>
</template>

<script>
import myServices from "../services/resource";
import { required, email } from "vuelidate/lib/validators";
import Texting from "./Texting";
import Button from "./Button.vue";
import Images from "./MyImages";
import axios from "axios";
import Confirmation from "./Confirmation";
import ForgotPassGoogle from "@/components/ForgotPassGoogle";

export default {
  components: {
    ForgotPassGoogle,
    Confirmation,
    Images,
    Button,
    Texting,
  },
  data: () => ({
    dialog: false,
    forgot_google: false,

    form: {
      email: "",
    },
  }),
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    onForgot() {
      if (this.$v.$invalid) {
        this.$v.form.$touch();
        this.dialog = true;
        return;
      }

      axios
        .post("/api/auth/forgot", this.form)
        .then(() => {
          this.hide1();
          this.$refs.confirmation.show();
        })
        .catch((error) => {
          this.show2();
          if (
            error.response.data.message ===
            "Provided email address registered with Google authorization"
          ) {
            this.forgot_google = true;
            return;
          }
          myServices.showErrorMessage(this, error);
          /*let message = "Oops, something went wrong";
					if (error.response && error.response.data) {
						message = error.response.data.message;
					}
					this.$toasted.error(message, {
						duration: 5000,
						className: "toast-error",
					});*/
        });
    },
    show2() {
      this.dialog = true;
    },
    hide1() {
      this.dialog = false;
      this.$v.$reset();
      this.form.email = "";
    },
  },
};
</script>
